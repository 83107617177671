@mixin get-color($class) {
  background-color: var(--#{$class}-background);
  color: var(--#{$class}-text);
  border-color: var(--#{$class}-border);
  &:active .clickable :not(.disabled) {
    background-color: var(--#{$class}-active);
  }
  [disabled] {
    background-color: red;
  }
  &:disabled {
    background-color: var(--#{$class}-disabled);
  }
  &.disabled {
    background-color: var(--#{$class}-disabled);
  }
  // & > img > svg {
  //   fill: var(--#{$class}-text);
  // }
  // &svg {
  //   fill: var(--#{$class}-text);
  //   &path {
  //     fill: var(--#{$class}-text);
  //   }
  // }
}

@mixin get-color-text($class) {
  color: var(--#{$class});
}
