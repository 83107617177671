@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary: #f29d1b;
  --primary-text: #ffffff;
  --primary-border: #f29d1b;
  --primary-active: #f5ae42;
  --primary-disabled: #f9cd8c;
  --primary-background: #f29d1b;
  --primary--light: #f29d1b;
  --accent: #bee4e3;
  --accent-text: #538785;
  --accent-border: #bee4e3;
  --accent-active: #6db0ae;
  --accent-disabled: #d9f6f5;
  --accent-background: #edfafa;
  --accent-light: #d4edec;
  --secondary: #fde3e3;
  --secondary-text: #f34f4f;
  --secondary-border: #fde3e3;
  --secondary-active: #ffc4c4;
  --secondary-disabled: #fddede;
  --secondary-background: #fde3e3;
  --tertiary: #ffffff;
  --tertiary-text: #404040;
  --tertiary-border: #e5e5e5;
  --tertiary-active: #f6f5f5;
  --tertiary-disabled: #f6f5f5;
  --tertiary-background: #ffffff;
  --light-grey1: #f8f9fa;
  --light-grey1-text: #a3a3a3;
  --light-grey1-border: #f8f9fa;
  --light-grey1-active: #dadcdd;
  --light-grey1-disabled: #9c9d9e;
  --light-grey1-background: #f8f9fa;
  --light-grey2: #e3e4e5;
  --light-grey2-text: rgb(63, 61, 61);
  --light-grey2-border: #e3e4e5;
  --light-grey2-active: #c1c2c4;
  --light-grey2-disabled: #78797a;
  --light-grey2-background: #e3e4e5;
  --light-grey6: #6c6d6f;
  --light-grey6-text: white;
  --light-grey6-border: #6c6d6f;
  --light-grey6-active: #5d5e5f;
  --light-grey6-disabled: #888a8d;
  --light-grey6-background: #6c6d6f;
  --light-grey10: #030317;
  --light-grey10-text: white;
  --light-grey10-border: #030317;
  --light-grey10-active: #0d0d22;
  --light-grey10-disabled: #27273a;
  --light-grey10-background: #030317;
  --error: #e40f0f;
  --error-text: #e40f0f;
  --error-border: #fca5a5;
  --error-active: #e63939;
  --error-disabled: #e77b7b;
  --error-background: #fef2f2;
  --warning: #f59e0b;
  --warning-text: #f59e0b;
  --warning-border: #fffbeb;
  --warning-active: #fffbeb;
  --warning-disabled: #f9e3c6;
  --warning-background: #fffbeb;
  --success: #22c55e;
  --success-text: #22c55e;
  --success-border: #f0fdf4;
  --success-active: #f0fdf4;
  --success-disabled: #f0fdf4;
  --success-background: #f0fdf4;
  --facebook: #1877f2;
  --facebook-text: #1877f2;
}

:root {
  --primary: #f29d1b;
  --primary-text: #ffffff;
  --primary-border: #f29d1b;
  --primary-active: #f5ae42;
  --primary-disabled: #f9cd8c;
  --primary-background: #f29d1b;
  --primary--light: #f29d1b;
  --accent: #bee4e3;
  --accent-text: #538785;
  --accent-border: #bee4e3;
  --accent-active: #6db0ae;
  --accent-disabled: #d9f6f5;
  --accent-background: #edfafa;
  --accent-light: #d4edec;
  --secondary: #fde3e3;
  --secondary-text: #f34f4f;
  --secondary-border: #fde3e3;
  --secondary-active: #ffc4c4;
  --secondary-disabled: #fddede;
  --secondary-background: #fde3e3;
  --tertiary: #ffffff;
  --tertiary-text: #404040;
  --tertiary-border: #e5e5e5;
  --tertiary-active: #f6f5f5;
  --tertiary-disabled: #f6f5f5;
  --tertiary-background: #ffffff;
  --light-grey1: #f8f9fa;
  --light-grey1-text: #a3a3a3;
  --light-grey1-border: #f8f9fa;
  --light-grey1-active: #dadcdd;
  --light-grey1-disabled: #9c9d9e;
  --light-grey1-background: #f8f9fa;
  --light-grey2: #e3e4e5;
  --light-grey2-text: rgb(63, 61, 61);
  --light-grey2-border: #e3e4e5;
  --light-grey2-active: #c1c2c4;
  --light-grey2-disabled: #78797a;
  --light-grey2-background: #e3e4e5;
  --light-grey6: #6c6d6f;
  --light-grey6-text: white;
  --light-grey6-border: #6c6d6f;
  --light-grey6-active: #5d5e5f;
  --light-grey6-disabled: #888a8d;
  --light-grey6-background: #6c6d6f;
  --light-grey10: #030317;
  --light-grey10-text: white;
  --light-grey10-border: #030317;
  --light-grey10-active: #0d0d22;
  --light-grey10-disabled: #27273a;
  --light-grey10-background: #030317;
  --error: #e40f0f;
  --error-text: #e40f0f;
  --error-border: #fca5a5;
  --error-active: #e63939;
  --error-disabled: #e77b7b;
  --error-background: #fef2f2;
  --warning: #f59e0b;
  --warning-text: #f59e0b;
  --warning-border: #fffbeb;
  --warning-active: #fffbeb;
  --warning-disabled: #f9e3c6;
  --warning-background: #fffbeb;
  --success: #22c55e;
  --success-text: #22c55e;
  --success-border: #f0fdf4;
  --success-active: #f0fdf4;
  --success-disabled: #f0fdf4;
  --success-background: #f0fdf4;
  --facebook: #1877f2;
  --facebook-text: #1877f2;
}

h1 b,
h2 b,
h3 b,
h4 b,
h5 b,
h6 b {
  font-weight: 520;
}

body {
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--light-grey6);
}

.body-10 {
  color: var(--light-grey10);
}

h1 {
  font-family: "Raleway", sans-serif;
  font-size: 3.5rem;
  line-height: 4rem;
  color: var(--primary);
  font-weight: 200;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-weight: 1000;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: var(--light-grey10);
}

h3 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--light-grey10);
}

small {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--light-grey6);
}

a {
  color: var(--primary);
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

.gold {
  color: var(--primary);
}

button, .center-content {
  display: flex;
  justify-content: center;
}

.container {
  background-color: var(--light-grey1);
  border-color: var(--light-grey2);
  border-radius: 8px;
  padding: 16px;
  border-style: solid;
  border-width: 1px;
}

.main-title-section {
  color: var(--primary);
  font-size: 1.5rem;
  text-align: center;
  background-color: var(--light-grey1);
  width: 100vw;
  padding: 16px;
}

.page-content {
  padding-left: 10vw;
  padding-right: 10vw;
  max-width: 1024px;
  margin: auto;
}

.main-content {
  width: 100%;
  min-height: 100%;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 5px 20px rgba(147, 151, 155, 0.76);
}

.-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.-stretch {
  align-items: stretch;
}

@media only screen and (max-width: 768px) {
  .-mobile-hidden {
    display: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .-desktop-hidden {
    display: none !important;
  }
}

.-margin8 {
  margin: 8px;
}

.-margin16 {
  margin: 16px;
}

.-padding24 {
  padding: 24px;
}

.-padding8 {
  padding: 8px;
}

.-full-height {
  height: 100%;
}

.-border-box {
  box-sizing: border-box;
}

.-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.-flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.-space-between {
  justify-content: space-between;
}

.-space-around {
  justify-content: space-around;
}

.-start {
  justify-content: flex-start;
}

.-center {
  justify-content: center;
  align-items: center;
}

.-full-width {
  width: 100%;
}

.-grow1 {
  flex-grow: 1;
}

.-grow2 {
  flex-grow: 2;
}

.-grow3 {
  flex-grow: 3;
}

.-grow5 {
  flex-grow: 5;
}

.-cards-list {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  max-height: 90svh;
  gap: 16px;
  align-self: stretch;
  flex-grow: 1;
}
.-cards-list > * {
  margin: 0.5rem;
  height: 100%;
}
@media only screen and (min-width: 769px) {
  .-cards-list > * {
    width: 30%;
  }
}
@media only screen and (max-width: 768px) {
  .-cards-list > * {
    /* For mobile phones: */
    width: 50%;
  }
}

.clickable, button {
  cursor: pointer;
}
.clickable[disabled], button[disabled] {
  cursor: not-allowed;
}
.clickable.disabled, button.disabled {
  cursor: not-allowed;
}

.clickable:active:not(.disabled, [disabled]), button:active:not(.disabled, [disabled]) {
  box-shadow: 0px 0px 1px rgba(147, 151, 155, 0.76);
}

.item, button {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.center-text, button {
  text-align: center;
}

.page-sub-title {
  margin-top: 40px;
  margin-bottom: 40px;
}

.-upper-case {
  text-transform: uppercase;
}

.primary {
  background-color: var(--primary-background);
  color: var(--primary-text);
  border-color: var(--primary-border);
}
.primary:active .clickable :not(.disabled), .primary:active button :not(.disabled) {
  background-color: var(--primary-active);
}
.primary [disabled] {
  background-color: red;
}
.primary:disabled {
  background-color: var(--primary-disabled);
}
.primary.disabled {
  background-color: var(--primary-disabled);
}

.primary-text {
  color: var(--primary);
}

.accent {
  background-color: var(--accent-background);
  color: var(--accent-text);
  border-color: var(--accent-border);
}
.accent:active .clickable :not(.disabled), .accent:active button :not(.disabled) {
  background-color: var(--accent-active);
}
.accent [disabled] {
  background-color: red;
}
.accent:disabled {
  background-color: var(--accent-disabled);
}
.accent.disabled {
  background-color: var(--accent-disabled);
}

.accent-text {
  color: var(--accent);
}

.warning {
  background-color: var(--warning-background);
  color: var(--warning-text);
  border-color: var(--warning-border);
}
.warning:active .clickable :not(.disabled), .warning:active button :not(.disabled) {
  background-color: var(--warning-active);
}
.warning [disabled] {
  background-color: red;
}
.warning:disabled {
  background-color: var(--warning-disabled);
}
.warning.disabled {
  background-color: var(--warning-disabled);
}

.warning-text {
  color: var(--warning);
}

.success {
  background-color: var(--success-background);
  color: var(--success-text);
  border-color: var(--success-border);
}
.success:active .clickable :not(.disabled), .success:active button :not(.disabled) {
  background-color: var(--success-active);
}
.success [disabled] {
  background-color: red;
}
.success:disabled {
  background-color: var(--success-disabled);
}
.success.disabled {
  background-color: var(--success-disabled);
}

.success-text {
  color: var(--success);
}

.secondary {
  background-color: var(--secondary-background);
  color: var(--secondary-text);
  border-color: var(--secondary-border);
}
.secondary:active .clickable :not(.disabled), .secondary:active button :not(.disabled) {
  background-color: var(--secondary-active);
}
.secondary [disabled] {
  background-color: red;
}
.secondary:disabled {
  background-color: var(--secondary-disabled);
}
.secondary.disabled {
  background-color: var(--secondary-disabled);
}

.secondary-text {
  color: var(--secondary);
}

.tertiary {
  background-color: var(--tertiary-background);
  color: var(--tertiary-text);
  border-color: var(--tertiary-border);
}
.tertiary:active .clickable :not(.disabled), .tertiary:active button :not(.disabled) {
  background-color: var(--tertiary-active);
}
.tertiary [disabled] {
  background-color: red;
}
.tertiary:disabled {
  background-color: var(--tertiary-disabled);
}
.tertiary.disabled {
  background-color: var(--tertiary-disabled);
}

.tertiary-text {
  color: var(--tertiary);
}

.light-grey1 {
  background-color: var(--light-grey1-background);
  color: var(--light-grey1-text);
  border-color: var(--light-grey1-border);
}
.light-grey1:active .clickable :not(.disabled), .light-grey1:active button :not(.disabled) {
  background-color: var(--light-grey1-active);
}
.light-grey1 [disabled] {
  background-color: red;
}
.light-grey1:disabled {
  background-color: var(--light-grey1-disabled);
}
.light-grey1.disabled {
  background-color: var(--light-grey1-disabled);
}

.light-grey1-text {
  color: var(--light-grey1);
}

.light-grey2 {
  background-color: var(--light-grey2-background);
  color: var(--light-grey2-text);
  border-color: var(--light-grey2-border);
}
.light-grey2:active .clickable :not(.disabled), .light-grey2:active button :not(.disabled) {
  background-color: var(--light-grey2-active);
}
.light-grey2 [disabled] {
  background-color: red;
}
.light-grey2:disabled {
  background-color: var(--light-grey2-disabled);
}
.light-grey2.disabled {
  background-color: var(--light-grey2-disabled);
}

.light-grey2-text {
  color: var(--light-grey2);
}

.light-grey3 {
  background-color: var(--light-grey3-background);
  color: var(--light-grey3-text);
  border-color: var(--light-grey3-border);
}
.light-grey3:active .clickable :not(.disabled), .light-grey3:active button :not(.disabled) {
  background-color: var(--light-grey3-active);
}
.light-grey3 [disabled] {
  background-color: red;
}
.light-grey3:disabled {
  background-color: var(--light-grey3-disabled);
}
.light-grey3.disabled {
  background-color: var(--light-grey3-disabled);
}

.light-grey3-text {
  color: var(--light-grey3);
}

.light-grey6 {
  background-color: var(--light-grey6-background);
  color: var(--light-grey6-text);
  border-color: var(--light-grey6-border);
}
.light-grey6:active .clickable :not(.disabled), .light-grey6:active button :not(.disabled) {
  background-color: var(--light-grey6-active);
}
.light-grey6 [disabled] {
  background-color: red;
}
.light-grey6:disabled {
  background-color: var(--light-grey6-disabled);
}
.light-grey6.disabled {
  background-color: var(--light-grey6-disabled);
}

.light-grey6-text {
  color: var(--light-grey6);
}

.light-grey10 {
  background-color: var(--light-grey10-background);
  color: var(--light-grey10-text);
  border-color: var(--light-grey10-border);
}
.light-grey10:active .clickable :not(.disabled), .light-grey10:active button :not(.disabled) {
  background-color: var(--light-grey10-active);
}
.light-grey10 [disabled] {
  background-color: red;
}
.light-grey10:disabled {
  background-color: var(--light-grey10-disabled);
}
.light-grey10.disabled {
  background-color: var(--light-grey10-disabled);
}

.light-grey10-text {
  color: var(--light-grey10);
}

.error {
  background-color: var(--error-background);
  color: var(--error-text);
  border-color: var(--error-border);
}
.error:active .clickable :not(.disabled), .error:active button :not(.disabled) {
  background-color: var(--error-active);
}
.error [disabled] {
  background-color: red;
}
.error:disabled {
  background-color: var(--error-disabled);
}
.error.disabled {
  background-color: var(--error-disabled);
}

.error-text {
  color: var(--error);
}

.facebook {
  background-color: var(--facebook-background);
  color: var(--facebook-text);
  border-color: var(--facebook-border);
}
.facebook:active .clickable :not(.disabled), .facebook:active button :not(.disabled) {
  background-color: var(--facebook-active);
}
.facebook [disabled] {
  background-color: red;
}
.facebook:disabled {
  background-color: var(--facebook-disabled);
}
.facebook.disabled {
  background-color: var(--facebook-disabled);
}

.facebook-text {
  color: var(--facebook);
}

.-form-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.-form-item {
  /* Auto layout */
  padding: 0px;
  gap: 8px;
  width: 100%;
  margin-bottom: 10px;
}
.-form-item *:not(.iti, burddy-monorepo-input-tel *) {
  width: 100%;
}
.-form-item burddy-monorepo-input-tel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.-form-item .error {
  background-color: transparent;
  color: var(--error);
}
.-form-item label {
  /* Paragraph/Small/Medium */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* Neutral/900 */
  color: #171717;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.-form-item label * {
  display: inline;
  background-color: transparent;
}

button {
  margin: 12px;
  margin: 12px;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 8px;
  border-style: solid;
  color: white;
  padding: 12px;
  box-shadow: 0px 0px 0px var(--primary);
  justify-content: center;
  align-items: center;
}
button img {
  margin-right: 12px;
  width: 1.2rem;
}

button.large {
  padding: 18px;
}
button.large img {
  width: 1.5rem;
}

button.small {
  padding: 2px;
}
button.small img {
  width: 0.7rem;
}

input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  /* Generic/White */
  background: #ffffff;
  /* Neutral/200 */
  border: 1px solid #e5e5e5;
  /* Drop shadow/XSmall */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* Paragraph/Small/Regular */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* Neutral/900 */
  color: #171717;
}
input::placeholder {
  /* Paragraph/Small/Regular */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* Neutral/400 */
  color: #a3a3a3;
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}
input .error {
  border-color: var(--error-border);
}
input:focus {
  border-color: var(--primary);
}

select {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  /* Generic/White */
  background: #ffffff;
  /* Neutral/200 */
  border: 1px solid #e5e5e5;
  /* Drop shadow/XSmall */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* Paragraph/Small/Regular */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* Neutral/900 */
  color: #171717;
}
select:focus {
  border-color: var(--primary);
}

textarea {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 12px;
  width: 100%;
  /* Generic/White */
  background: #ffffff;
  /* Neutral/200 */
  border: 1px solid #e5e5e5;
  /* Drop shadow/XSmall */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  /* Paragraph/Small/Regular */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* Neutral/900 */
  color: #171717;
}
textarea::placeholder {
  /* Paragraph/Small/Regular */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  /* Neutral/400 */
  color: #a3a3a3;
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}
textarea:focus {
  border-color: var(--primary);
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #edfafa;
}

body {
  font-family: "Raleway", sans-serif;
}

hr {
  width: 80%;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-color: var(--light-grey2);
}

a {
  color: var(--primary);
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
textarea {
  font-size: 16px;
}