select {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;

  /* Generic/White */
  background: #ffffff;

  /* Neutral/200 */
  border: 1px solid #e5e5e5;

  /* Drop shadow/XSmall */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  /* Paragraph/Small/Regular */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */
  display: flex;
  align-items: center;

  /* Neutral/900 */
  color: #171717;

  &:focus {
    border-color: $primary;
  }
}
