// Paddings
$default-padding: 16px;
$default-title-padding: 20px;
$page-content-padding-horizontal: 10vw;
$default-element-padding: 20px;

// Margins
$input-minimum-margin: 12px;
$page-sub-title-margin-vertical: 40px;
$elements-margin-vertical: 10px;
$content-horizontal-margin: 5px;

// Radius
$default-radius: 8px;
$default-button-radius: 12px;

// Nav bar
$nav-bar-height: 48px;
$nav-bar-margin-bottom: 64px;

// Content Margin
$content-margin-top: 24px;
