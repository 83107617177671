.container {
  background-color: $light-grey1;
  border-color: $light-grey2;
  border-radius: $default-radius;
  padding: $default-padding;
  border-style: solid;
  border-width: 1px;
}

// The class representing main title of a page
.main-title-section {
  color: $primary;
  font-size: 1.5rem;
  text-align: center;
  background-color: $light-grey1;
  width: 100vw;
  padding: $default-padding;
}

.center-content {
  @extend %flex-center;
}

.page-content {
  padding-left: $page-content-padding-horizontal;
  padding-right: $page-content-padding-horizontal;
  max-width: 1024px;
  margin: auto;
}

.main-content {
  width: 100%;
  min-height: 100%;
}

.card {
  background-color: white;
  border-radius: $default-radius;
  box-shadow: 0px 5px 20px rgb(147 151 155 / 76%);
}

.-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.-stretch {
  align-items: stretch;
}

.-mobile-hidden {
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}

.-desktop-hidden {
  @media only screen and (min-width: 769px) {
    display: none !important;
  }
}

.-margin8 {
  margin: 8px;
}

.-margin16 {
  margin: 16px;
}

.-padding24 {
  padding: 24px;
}

.-padding8 {
  padding: 8px;
}

.-full-height {
  height: 100%;
}

.-border-box {
  box-sizing: border-box;
}

.-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.-flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.-space-between {
  justify-content: space-between;
}

.-space-around {
  justify-content: space-around;
}

.-start {
  justify-content: flex-start;
}

.-center {
  justify-content: center;
  align-items: center;
}

.-full-width {
  width: 100%;
}

.-grow1 {
  flex-grow: 1;
}

.-grow2 {
  flex-grow: 2;
}

.-grow3 {
  flex-grow: 3;
}

.-grow5 {
  flex-grow: 5;
}

.-cards-list {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  max-height: 90svh;
  gap: 16px;
  align-self: stretch;
  flex-grow: 1;

  & > * {
    margin: 0.5rem;
    height: 100%;
    @media only screen and (min-width: 769px) {
      width: 30%;
    }
    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      width: 50%;
    }
  }
}
