:root {
  // PRIMARY
  --primary: #f29d1b;
  --primary-text: #ffffff;
  --primary-border: #f29d1b;
  --primary-active: #f5ae42;
  --primary-disabled: #f9cd8c;
  --primary-background: #f29d1b;
  --primary--light: #f29d1b;

  // ACCENT
  --accent: #bee4e3;
  --accent-text: #538785;
  --accent-border: #bee4e3;
  --accent-active: #6db0ae;
  --accent-disabled: #d9f6f5;
  --accent-background: #edfafa;
  --accent-light: #d4edec;

  // SECONDARY
  --secondary: #fde3e3;
  --secondary-text: #f34f4f;
  --secondary-border: #fde3e3;
  --secondary-active: #ffc4c4;
  --secondary-disabled: #fddede;
  --secondary-background: #fde3e3;

  // TERTIARY
  --tertiary: #ffffff;
  --tertiary-text: #404040;
  --tertiary-border: #e5e5e5;
  --tertiary-active: #f6f5f5;
  --tertiary-disabled: #f6f5f5;
  --tertiary-background: #ffffff;

  // Light GREY 1
  --light-grey1: #f8f9fa;
  --light-grey1-text: #a3a3a3;
  --light-grey1-border: #f8f9fa;
  --light-grey1-active: #dadcdd;
  --light-grey1-disabled: #9c9d9e;
  --light-grey1-background: #f8f9fa;

  // Light GREY 2
  --light-grey2: #e3e4e5;
  --light-grey2-text: rgb(63, 61, 61);
  --light-grey2-border: #e3e4e5;
  --light-grey2-active: #c1c2c4;
  --light-grey2-disabled: #78797a;
  --light-grey2-background: #e3e4e5;

  // Light GREY 6
  --light-grey6: #6c6d6f;
  --light-grey6-text: white;
  --light-grey6-border: #6c6d6f;
  --light-grey6-active: #5d5e5f;
  --light-grey6-disabled: #888a8d;
  --light-grey6-background: #6c6d6f;

  // Light GREY 10
  --light-grey10: #030317;
  --light-grey10-text: white;
  --light-grey10-border: #030317;
  --light-grey10-active: #0d0d22;
  --light-grey10-disabled: #27273a;
  --light-grey10-background: #030317;

  // ERROR
  --error: #e40f0f;
  --error-text: #e40f0f;
  --error-border: #fca5a5;
  --error-active: #e63939;
  --error-disabled: #e77b7b;
  --error-background: #fef2f2;

  // WARNING
  --warning: #f59e0b;
  --warning-text: #f59e0b;
  --warning-border: #fffbeb;
  --warning-active: #fffbeb;
  --warning-disabled: #f9e3c6;
  --warning-background: #fffbeb;

  // SUCCESS
  --success: #22c55e;
  --success-text: #22c55e;
  --success-border: #f0fdf4;
  --success-active: #f0fdf4;
  --success-disabled: #f0fdf4;
  --success-background: #f0fdf4;

  // Facebook
  --facebook: #1877f2;
  --facebook-text: #1877f2;
  // --facebook-border: #1877f2;
  // --facebook-active: #1877f2;
  // --facebook-disabled: #1877f2;
  // --facebook-background: #1877f2;
}

// SASS Variables
$primary: var(--primary);
$accent: var(--accent);
$secondary: var(--secondary);
$warning: var(--warning);
$success: var(--success);
$tertiary: var(--tertiary);
$light-grey1: var(--light-grey1);
$light-grey2: var(--light-grey2);
$light-grey3: rgba(147, 151, 155, 0.76);
$light-grey6: var(--light-grey6);
$light-grey10: var(--light-grey10);
$error: var(--error);
$facebook: var(--facebook);
