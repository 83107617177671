.clickable {
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.clickable:active:not(.disabled, [disabled]) {
  box-shadow: 0px 0px 1px rgb(147 151 155 / 76%);
}

.item {
  display: flex;
  justify-content: space-between;
  padding: $default-padding;
}

.center-text {
  text-align: center;
}

.page-sub-title {
  margin-top: $page-sub-title-margin-vertical;
  margin-bottom: $page-sub-title-margin-vertical;
}

.-upper-case {
  text-transform: uppercase;
}
