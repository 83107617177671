.primary {
  @include get-color(primary);
}

.primary-text {
  @include get-color-text(primary);
}

.accent {
  @include get-color(accent);
}

.accent-text {
  @include get-color-text(accent);
}

.warning {
  @include get-color(warning);
}

.warning-text {
  @include get-color-text(warning);
}

.success {
  @include get-color(success);
}

.success-text {
  @include get-color-text(success);
}

.secondary {
  @include get-color(secondary);
}

.secondary-text {
  @include get-color-text(secondary);
}

.tertiary {
  @include get-color(tertiary);
}

.tertiary-text {
  @include get-color-text(tertiary);
}

.light-grey1 {
  @include get-color(light-grey1);
}

.light-grey1-text {
  @include get-color-text(light-grey1);
}

.light-grey2 {
  @include get-color(light-grey2);
}

.light-grey2-text {
  @include get-color-text(light-grey2);
}

.light-grey3 {
  @include get-color(light-grey3);
}

.light-grey3-text {
  @include get-color-text(light-grey3);
}

.light-grey6 {
  @include get-color(light-grey6);
}

.light-grey6-text {
  @include get-color-text(light-grey6);
}

.light-grey10 {
  @include get-color(light-grey10);
}

.light-grey10-text {
  @include get-color-text(light-grey10);
}

.error {
  @include get-color(error);
}

.error-text {
  @include get-color-text(error);
}

.facebook {
  @include get-color(facebook);
}

.facebook-text {
  @include get-color-text(facebook);
}
