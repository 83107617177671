// @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '_colors.scss';

$f-body: 'Raleway', sans-serif;

$weight-for-bold-in-titles: 520;

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-weight: lighter;
  b {
    font-weight: $weight-for-bold-in-titles;
  }
}

body {
  font-family: $f-body;
  font-weight: 200;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $light-grey6;
}

.body-10 {
  color: $light-grey10;
}

h1 {
  font-family: $f-body;
  font-size: 3.5rem;
  line-height: 4rem;
  color: $primary;
  font-weight: 200;
}

h2 {
  font-family: $f-body;
  font-weight: 1000;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: -0.02em;
  color: $light-grey10;
}

h3 {
  font-family: $f-body;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $light-grey10;
}

small {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: $light-grey6;
}

a {
  color: $primary;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1rem;
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

.gold {
  color: $primary;
}
